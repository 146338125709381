import React, { use, useCallback, useEffect, useRef, useState } from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box, Card, CardHeader, CardMedia, CircularProgress, List, ListItem, Stack, Typography, styled } from '@mui/material';
import BuilderSelect from '@/store/builderStoreSelector';
import { Texture } from '@/store/builderSlice';
import { actions as builderActions } from "@/store/builderSlice";
import { useDispatch } from 'react-redux';
import { ProductPlacementType, SurfaceType } from '@/utils/builder/builderConstants';
import useScreenSize from '@/utils/useScreenSize';
import { v4 as uuid } from 'uuid';


const floorTextureCategories = [
  'Wood',
  'Carpet',
  'Tile',
  'Concrete'
];

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme, ...props }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    fontSize: props!.style!.fontSize,
    color: props!.style!.color
  },
  '& .MuiAccordionSummary-content:hover': {
    color: '#0D908E',
    fontWeight: 'bold',
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const TextureThumbnailsList = (props: { textures: Texture[], applyTexture: Function }) => {

  return <List sx={{
    maxHeight: 555,
    overflow: 'auto',
    width: '100%',
    maxWidth: 360,
    bgcolor: 'background.paper',
    paddingTop: 0,
    paddingBottom: 0
  }}>
    {props.textures.map((texture: Texture) => (
      <ListItem key={texture.id}
        sx={{
          paddingTop: 0,
          paddingBottom: '6px',
          paddingLeft: 0,
          paddingRight: 0
        }}
      >
        <Card key={texture.id} sx={{ m: 1, maxWidth: '100%', boxShadow: 'unset', display: 'flex' }}>
          <Box component={"div"} onClick={() => { props.applyTexture(texture) }}>
            <Stack spacing={1} direction="column" sx={{ alignItems: 'center' }}>
              <CardHeader
                sx={{ padding: '0px' }}
                title={
                  <CardMedia
                    image={texture.textureUrl}
                    component="img"
                    sx={{
                      ":hover": {
                        borderColor: "primary.main",
                        borderWidth: "4px",
                        borderStyle: "solid",
                      },
                      borderRadius: '5px',
                      cursor: 'pointer',
                      width: '50px',
                      maxHeight: '40px !important'
                    }}
                  />
                }>
              </CardHeader>
              <Typography variant="body2" sx={{ color: 'primary', paddingTop: '5px', lineHeight: '15px', textAlign: 'center' }}>
                {texture.name}
              </Typography>
            </Stack>
          </Box>
        </Card>
      </ListItem>
    ))
    }
  </List >
}

export default function FloorTextureTray(props: { applyTexture: Function }) {
  const dispatch = useDispatch();
  const textures: Texture[] = BuilderSelect.designContent().textures;
  // const floorTextures: Texture[] = textures.filter(texture => texture.surfaceType === 'Floor');
  let floorTextures: Texture[] = [];
  if(textures && textures.length > 0 && textures[0]) {  
    floorTextures = textures.filter(texture => texture.surfaceType === 'Floor');
  }
  const [expanded, setExpanded] = useState<string | false>('');
  const screenSize = useScreenSize();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };


  const getStylesAccordion = () => {
    const comps: JSX.Element[] = [];
    const currentTextures = floorTextures.filter(texture => texture.category === expanded)
    floorTextureCategories.map((floorTextureCategory) => (
      comps.push(<Accordion
        key={floorTextureCategory}
        expanded={expanded === floorTextureCategory}
        onChange={handleChange(floorTextureCategory)}
        style={{ border: 0 }}
      >
        <AccordionSummary
          id="panel1d-header"
          expandIcon={<IconButton style={{ display: 'none' }}><ExpandMoreIcon /></IconButton>}
          style={{
            backgroundColor: 'white',
            fontSize: '14px',
            color: expanded === floorTextureCategory ? '#0D908E' : (expanded === '' || !expanded) ? '#000000' : '#C0C0C0',
            fontWeight: expanded === floorTextureCategory ? 'bold' : 'normal'
          }}
        >
          {floorTextureCategory}
        </AccordionSummary>
        {expanded === floorTextureCategory && (
          <AccordionDetails style={{ padding: 0, border: 0 }}>
            <TextureThumbnailsList textures={currentTextures} applyTexture={props.applyTexture} />
          </AccordionDetails>)
        }
      </Accordion >
      )));
    return comps;
  }

  const [currentTextures, setCurrentTextures] = useState<any>(null);
    const [currentFloor, setCurrentFloor] = useState('Wood');

  const getStylesTabMweb = () => {
    const comps: JSX.Element[] = [];
    
    const changeTexture = (txtur: string) => {
      setCurrentFloor(txtur)
      setCurrentTextures(floorTextures.filter(texture => texture.category === txtur))
    };

    const test =
      <>
        <Box component="div" sx={{ display: 'flex', borderBottom: 1, borderColor: 'divider' }}>
          {
            floorTextureCategories.map((floorTextureCategory, index) => {
              return (
                <React.Fragment key={uuid()}>
                  <Box key={`floor-${floorTextureCategory}-${index}`} component="div" sx={{
                      padding: '5px 10px', 
                      cursor: 'pointer',
                      borderBottom: '1px',
                      borderBottomStyle: 'solid',
                      fontWeight: '700',
                      fontSize: '15px',
                      borderBottomColor: floorTextureCategory === currentFloor ? 'primary.main': 'transparent',
                      color: floorTextureCategory === currentFloor ? 'primary.main': 'inherit',
                    }} onClick={() => changeTexture(floorTextureCategory)}>
                    {floorTextureCategory}
                  </Box>
                </React.Fragment>
              )
            })
          }
        </Box>
        <Box component="div" sx={{ display: 'flex' }}>
          {
            floorTextureCategories.map((floorTextureCategory, index) => {
              const currentTextures = floorTextures.filter(texture => texture.category === floorTextureCategory)
              return (
                <React.Fragment key={uuid()}>
                  {floorTextureCategory === currentFloor && <div style={{width: '100%'}} key={`texture-${floorTextureCategory}-${index}`}>
                    <TextureThumbnailsList textures={currentTextures} applyTexture={props.applyTexture} />
                  </div>}
                </React.Fragment>
              )
            }
            )
          }
        </Box>
      </>

    return test;
  }

  return (
    <>    
    {
      // screenSize.width > 768 ? (
      //   <Box component={'div'} sx={{
      //     maxWidth: '150',
      //     maxHeight: 'calc(100vh - 200px)',
      //     overflowY: 'auto'
      //   }}
      //   >
      //     {getStylesAccordion()}
      //   </ Box>
      // ) : (
      //   <Box component={'div'} sx={{
      //     maxWidth: '100%',
      //     maxHeight: 'auto',
      //   }}
      //   >
      //     {getStylesTabMweb()}
      //   </ Box>
      // )

      <Box component={'div'} sx={{
        maxWidth: '100%',
        maxHeight: 'auto',
      }}
      >
        {getStylesTabMweb()}
      </ Box>
    }
    </>
  );
}