import { prepareErrorObj } from "@/components/nesterror";
import axiosObj from "./axios";
import { getDesignById } from "./homeSlice";
import { getEitherCustIdFromLs } from "@/utils/useUserInfo";

export interface ProjectMetaData {
    roomType: string;
    styleId: string;
    designId: string;
    designImage: string;
}
export interface ProjectData {
    projectId: string;
    projectName: string;
    projectImage: string;
    status: string;
    metaData?: ProjectMetaData;
    products?: string;
    roomModel?: any;
    origin: "DESIGN" | "BUILDER" | "CUSTOM";
}

export interface ProjectProduct {
    productId: string;
    productName: string;
    productImage: string;
    model3dAsset: {
        bundleName: string;
        bundleUrl: string;
        jsonUrl: string;
    };
    thumbnailAsset: {
        bundleName: string;
        bundleUrl: string;
        jsonUrl: string;
    };
    model3dUrl: string;
    productDescription: string;
    productDesignImage: string[];
    productSubTypeID: number;
    productSubTypeName: string;
    productSubTypeDisplayName: string;
    productTypeId: number;
    productTypeName: string;
    productTypeDisplayName: string;
    productShopifyId?: string;
    availability?:string;
    variantId?:string;
    price?:number;
    currency?:string;
  }

export const getProjects = async (designId?: string, projectId?: string, brand?: string) => {
    let url = `/api/projects`;
    if (designId) url = url + `?designId=${designId}`;
    if (projectId) url = url + `/${projectId}`;
    if (brand) url = url + `${designId || projectId ? "&" : "?"}brand=${brand}`;
    return await axiosObj.get(url,{
      headers: {
        'Customer-Id': getEitherCustIdFromLs()

      }
    })
      .then((response) => {
        if(!response || !response.data) throw new Error("No response from server for getProjects");
        return response.data
      }, (err) => {
        const errorObj = Object.assign({ description: "NEST_SPA:Slices:getProjects:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE" }, prepareErrorObj(err))
        console.log(errorObj);
        throw errorObj;
      });
}

export const createProject = async(projectData: any,  brand?: string) => {
    return await axiosObj.post(`/api/projects`, projectData,{
      headers: {
        'Customer-Id': getEitherCustIdFromLs()
      }
    })
      .then((response) => {
        if(!response || !response.data) throw new Error("No response from server for createProject");
        return response.data
      }, (err) => {
        const errorObj = Object.assign({ description: "NEST_SPA:Slices:createProject:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE" }, prepareErrorObj(err))
        console.log(errorObj);
        throw errorObj;
      });
}

// want to add deleteProject 
export const deleteProject = async(projectId: string, brand?: string) => {
    return await axiosObj.delete(`/api/projects/${projectId}`,{
      headers: {
        'Customer-Id': getEitherCustIdFromLs()
      }
    })
      .then((response) => {
        if(!response || !response.data) throw new Error("No response from server for deleteProject");
        return response.data
      }, (err) => {
        const errorObj = Object.assign({ description: "NEST_SPA:Slices:deleteProject:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE" }, prepareErrorObj(err))
        console.log(errorObj);
        throw errorObj;
      });
}

//want to add updateProject
export const updateProject = async(projectId: string, projectData: any, brand?: string) => {
    return await axiosObj.put(`/api/projects/${projectId}`, projectData)
      .then((response) => {
        if(!response || !response.data) throw new Error("No response from server for updateProject");
        return response.data
      }, (err) => {
        const errorObj = Object.assign({ description: "NEST_SPA:Slices:updateProject:Exception", errorCode: "NEST_SPA:PRODUCTS_PAGE" }, prepareErrorObj(err))
        console.log(errorObj);
        throw errorObj;
      });
}

export const getProjectDesign = async (designId: any) => {
  return await getDesignById(designId);
}


