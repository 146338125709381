import Head from 'next/head';
import CssBaseline from '@mui/material/CssBaseline';
import HeaderComponent from './header';
import FooterComponent from './footer';
import CmsRenderer from "@/components/cms_renderer";
import SearchBar from './product_search_bar';
import { useEffect, useState } from 'react';
import { transformTextToHtml } from "@/components/cms_renderer";
import { useRouter } from 'next/router';
export default function Layout(props) {
  const router = useRouter();
  const { createRoomPlan } = router.query;
  const[searchBarOpen,setSearchBarOpen]=useState(false)
  const isSearchPage = router.pathname.includes('/search')
  useEffect(()=>{
    if(isSearchPage){
      setSearchBarOpen(true)
    }
  },[])
  const [query, setQuery] = useState(()=>{
    if (typeof window !== 'undefined') {
      return sessionStorage.getItem('userSearchQuery') || '';
    }
    return ''; 
  })
  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('userSearchQuery', query);
    }
  }, [query]);
  const { children, brand, popupView, headerType, globalData,  loginEl, setLoginEl, headerMenuJson, pageInserts, env } = props;
  const cmsBanner = pageInserts?.headerTop?.items[0]?.content;
  let path = props.path.split("?")[0];
  const isPopupView = popupView || false;
  const noHeaderFooterList = [
    "login",
    "successconfirmation",
    'product3d'
  ];
  const shortHeaderList = [
    "stylequiz",
    "roomquiz",
    "recommendations",
    "builder",
    "cart",
    "uploadInstructions"
  ]
  return (
    <>
      {!isPopupView &&
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>}
      {/*cmsBanner && <div className="promo_banner">
        <span>{transformTextToHtml(cmsBanner)}</span>
      </div>*/}
      {!isPopupView && path && !path.includes('threed') && !noHeaderFooterList.includes(path.split("/")[1])
            && (
            <>
            <div className={`${pageInserts?.headerTop?.enabled ? 'header_container_with_banner' : 'header_container'}`}>
              <HeaderComponent  
                loginEl={loginEl} 
                setLoginEl={setLoginEl} 
                loginCheckLoading={props.loaded} 
                brand={brand} 
                globalData={globalData} 
                headerType={shortHeaderList.includes(path.split("/")[1]) ? 'short' : 'full'}
                headerMenuJson={headerMenuJson}
                headerTopCmsData={pageInserts.headerTop}
                headerBottomCmsData={pageInserts.headerBottom}
                searchBarOpen={searchBarOpen}
                setSearchBarOpen={setSearchBarOpen}
              />
            </div>
             </>
          )}
      <div className={`main-wrapper ${path === '/' ? 'full_width' : ''}`}>
        <div id="signup-a">
          <div className='container_wrapper'>
            <CssBaseline />
            {!createRoomPlan && searchBarOpen && <div style={{height:'80px'}}>
                <SearchBar setSearchBarOpen={setSearchBarOpen} query={query} setQuery={setQuery}/>
             </div>}
            <main>{children}</main>
          </div>
        </div>
      </div>
      {pageInserts.footerContent && pageInserts.footerContent.enabled  && <CmsRenderer sectionCmsData={pageInserts.footerContent}  sectionCmsKey={'footerContent'} vendorName={brand} env={env} />}
      { !createRoomPlan && !isPopupView && path && !path.includes('threed') && !noHeaderFooterList.includes(path.split("/")[1]) 
        && <FooterComponent brand={brand} />
      }
    </>
  )
}